import { Layout } from "../components/Layout";
import { Container, Header, Grid } from "semantic-ui-react";
import Link from "next/link";

export default function Custom404() {
  return (
    <Layout title={`Not Found | Listatto`}>
      <Container style={{ marginTop: 50 }}>
        <Grid centered doubling verticalAlign="middle" stretched padded>
          <Grid.Column width={8} textAlign="center">
            <Header icon>
              404 Not Found!
              <Header.Subheader>
                We couldn't find what you were looking for.
              </Header.Subheader>
            </Header>
            <Link href="/">Go to Homepage &rarr;</Link>
          </Grid.Column>
        </Grid>
      </Container>
    </Layout>
  );
}
